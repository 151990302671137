<template>
<div class="my-4">
  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'Videos'}"  variant="light">
    <template #button-content>
      {{$t('videos.videos')}} <BBadge pill variant="danger" v-if="pendingVideos>0"> {{ pendingVideos }}</BBadge>
    </template>
    <b-dropdown-item :to="{name: 'AddVideo'}">{{ $t('videos.add-video') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'UnpublishedVideos'}">{{ $t('videos.unpublished') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'PendingVideos'}">
        {{ $t('videos.pending') }} <BBadge pill variant="danger" v-if="pendingVideos > 0"> {{ pendingVideos }}</BBadge>
    </b-dropdown-item>
    <b-dropdown-item :to="{name: 'DeletedVideos'}">{{ $t('videos.deleted') }}</b-dropdown-item>
  </b-dropdown>

  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'AllAudio'}"  variant="light">
    <template #button-content>
      {{$t('audio.audio')}} <BBadge pill variant="danger" v-if="pendingAudio>0"> {{ pendingAudio }}</BBadge>
    </template>
    <b-dropdown-item :to="{name: 'AddAudio'}">{{ $t('audio.add-audio') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'UnpublishedAudio'}">{{ $t('audio.unpublished') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'PendingAudio'}">
        {{ $t('audio.pending') }} <BBadge pill variant="danger" v-if="pendingAudio > 0"> {{ pendingAudio }}</BBadge>
    </b-dropdown-item>
    <b-dropdown-item :to="{name: 'DeletedAudio'}">{{ $t('audio.deleted') }}</b-dropdown-item>
  </b-dropdown>
     
  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'Images'}" variant="light">
    <template #button-content>
      {{ $t('images.images') }} <BBadge pill variant="danger" v-if="pendingImages>0"> {{ pendingImages }}</BBadge>
    </template>
    <b-dropdown-item :to="{name: 'AddImage'}">{{ $t('images.add-image') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'UnpublishedImages'}">{{ $t('images.unpublished') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'PendingImages'}">
      {{ $t('images.pending') }} <BBadge pill variant="danger" v-if="pendingImages > 0"> {{ pendingImages }}</BBadge>
    </b-dropdown-item>
    <b-dropdown-item :to="{name: 'DeletedImages'}">{{ $t('images.deleted') }}</b-dropdown-item>
  </b-dropdown>

  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'Pages'}" variant="light" :text="$t('pages.pages')">
    <b-dropdown-item :to="{name: 'AddPage'}">{{ $t('pages.add-page') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'UnpublishedPages'}">{{ $t('pages.unpublished') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'DeletedPages'}">{{ $t('pages.deleted') }}</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-text>{{ $t('pages.menu') }}</b-dropdown-text>
    <b-dropdown-item :to="{name: 'Menu'}">{{ $t('pages.all-menus') }}</b-dropdown-item>
  </b-dropdown>

  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'Tags'}" variant="light" :text="$t('tags.tags')">
    <b-dropdown-item :to="{name: 'AddTag'}">{{ $t('tags.add-tag') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'UnpublishedTags'}">{{ $t('tags.unpublished-tags') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'DeletedTags'}">{{ $t('tags.deleted-tags') }}</b-dropdown-item>
  </b-dropdown>

  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'Users'}" variant="light" :text="$t('users.users')">
    <b-dropdown-item :to="{name: 'AddUser'}">{{ $t('users.add-user') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'DisabledUsers'}">{{ $t('users.disabled-users') }}</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-text>{{ $t('users.roles') }}</b-dropdown-text>
    <b-dropdown-item :to="{name: 'Roles'}">{{ $t('users.all-roles') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'AddUserRole'}">{{ $t('users.add-role') }}</b-dropdown-item>
  </b-dropdown>

  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'Languages'}" variant="light" :text="$t('language.all-languages')">
    <b-dropdown-item :to="{name: 'AddLanguage'}">{{ $t('language.add-language') }}</b-dropdown-item>
    <b-dropdown-item :to="{name: 'DisabledLanguages'}">{{ $t('language.disabled-langauges') }}</b-dropdown-item>
  </b-dropdown>
  
  <b-dropdown class="mb-2 mr-2" split :split-to="{name: 'Statistics'}"   :text="$t('stat.statistics')" variant="light">
    <b-dropdown-item :to="{name: 'DownloadStatistic'}">{{ $t('stat.download-statistics') }}</b-dropdown-item>
  </b-dropdown>
</div>
</template>

<script>
import { BBadge, CardPlugin } from 'bootstrap-vue'

export default {
  name: "SuperAdministratorNav",
  components: { BBadge },
  data: function() {
    return {
      admin: {},
      pendingVideos: 0, 
      pendingImages: 0,
      pendingAudio: 0
    }
  },
  created: async function() {
    this.admin = this.$root.user;
    await this.$http.get('/cms/api/media/video/pending-count/', { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.pendingVideos = r.data;
    }).catch(e => {
     
    })
    await this.$http.get('/cms/api/media/image/pending-count/', { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.pendingImages = r.data;
    }).catch(e => {
      
    })
    await this.$http.get('/cms/api/media/audio/pending-count/', { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.pendingAudio = r.data;
    }).catch(e => {
      
    })

  }
}
</script>
<style></style>
